<section class="segments container px-3 container-fhd">
  <div class="segments__title">
    <h2 [innerHTML]="title | translate"></h2>
  </div>

  <div class="row g-3">
    <div class="col col-sm-6 col-lg-3" *ngFor="let segment of data; trackBy: segmentTrackBy">
      <qsc-segment-card [data]="segment"></qsc-segment-card>
    </div>
  </div>
</section>
